.warpper {
  background: #e2e8f3;
  .sectionBox {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background: url("/images/404.png") no-repeat 50% 50%;
    background-size: 600px auto;
  }
}
